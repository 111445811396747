<div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">    
    <div class="carousel-inner">
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(2%,0%)">
        <manderin-card-one-stop-shop></manderin-card-one-stop-shop>     
      </div>
      <div class="carousel-item active" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <manderin-card-vat></manderin-card-vat>
      </div>
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <manderin-card-office-space></manderin-card-office-space>
      </div>
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <manderin-card-hr-management></manderin-card-hr-management>      
      </div>
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <manderin-card-partner-management></manderin-card-partner-management>      
      </div>
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <manderin-card-business-travel></manderin-card-business-travel>      
      </div>
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <manderin-card-sales-hunting></manderin-card-sales-hunting>      
      </div>
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <manderin-card-translations></manderin-card-translations>     
      </div>
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <manderin-card-financial-risk></manderin-card-financial-risk>     
      </div>
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <manderin-card-company-legal-advice></manderin-card-company-legal-advice>     
      </div>
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <manderin-card-export-import></manderin-card-export-import>    
      </div>
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <manderin-card-company-insurances></manderin-card-company-insurances>    
      </div>
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <manderin-card-network-management></manderin-card-network-management>    
      </div>
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <manderin-card-team-support></manderin-card-team-support>    
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">以前的</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">下一个</span>
    </a>
  </div>