<div class="card text-white border-warning border-1">
    <img src="assets/images/finance2.jpg" class="card-img">
    <div class="card-img-overlay">
      <h4 class="card-title rounded" style="background: rgb(255, 128, 0);background: rgba(255, 128, 0, 0.8);color: #ffffff; text-align: center;">财务风险管理</h4>
    </div>
  
    <ul class="list-group list-group-flush bg-white text-dark">
      <li class="list-group-item">考虑获得欧元收入</li>
      <li class="list-group-item">任何市场动荡，例如Covid危机，都可以迅速改变汇率</li>
      <li class="list-group-item">对于这种和其他财务方案，风险管理可以防止重大损失和/或增加您的利润</li>
    </ul>
  </div>