<div class="card text-white border-warning border-1">
    <img src="assets/images/cZJw2VzN.jpg" class="card-img">
    <div class="card-img-overlay">
    <h4 class="card-title rounded" style="background: rgb(255, 128, 0);background: rgba(255, 128, 0, 0.6);color: #ffffff; text-align: center;position:relative;transform:translate(0%,-55%)">团队支持</h4>
    </div>
    <div class="card-header" style="background-color: peru">
      团队+个人支持
    </div>
    <ul class="list-group list-group-flush bg-white text-dark">
      <li class="list-group-item">我们组织团队活动，以娱乐和团队建设</li>
      <li class="list-group-item">与业务相关的活动，例如场外研讨会</li>
      <li class="list-group-item">对个人的本地支持：住房，医生，银行，保险，国际儿童学校，娱乐场所</li>
    </ul>
  </div>