<div class="card text-white border-warning border-1">
    <img src="assets/images/scott-graham-5fNmWej4tAA-unsplash.jpg" class="card-img">
    <div class="card-img-overlay">
      <h4 class="card-title rounded" style="background: rgb(255, 128, 0);background: rgba(255, 128, 0, 0.8);color: #ffffff; text-align: center;">增值税咨询</h4>
      <p class="card-text"></p>
    </div>
    <div class="card-header" style="background-color: peru">
      我们为您提供与荷兰增值税要求相关的各种服务
    </div>
    <ul class="list-group list-group-flush bg-white text-dark">
      <li class="list-group-item">增值税注册</li>
      <li class="list-group-item">进口增值税延期许可证申请</li>
      <li class="list-group-item">增值税退税</li>
    </ul>
  </div>