<form style="max-width: 200pt;" (ngSubmit)="onSubmit()" #messageForm="ngForm">
    <div class="form-group" style="max-width: 230pt">
        <input type="text" class="form-control" id="email" placeholder="电子邮件" required [(ngModel)]="message.email" name="email">
        <small id="emailHelp" class="form-text text-muted">我们绝不会与其他任何人共享您的电子邮件</small>
    </div>
    <div class="form-group" style="max-width: 200pt">
        <input type="text" class="form-control" id="name" placeholder="名称" [(ngModel)]="message.name" name="name">
    </div>
    <div class="form-group" style="max-width: 200pt">
        <textarea class="form-control" id="InputMessage1" rows="5" placeholder="信息" [(ngModel)]="message.message" name="message" style="max-width: 200pt"></textarea>
    </div>    
  
    <button type="submit" class="btn btn-primary">保持联系</button>    
    <div *ngIf="submitted" style="color: white; position:absolute;transform:translate(150%,-130%)">谢谢！</div>
</form>