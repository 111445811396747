<div *ngIf="deviceDesktop">
    <english-top></english-top>
</div>  
<div *ngIf="!deviceDesktop">
    <english-top-mob></english-top-mob>
</div>

<div class="container">
    
    <h1>Powered By Statement</h1>

    <div>This Powered By statement was last updated on 11 November 2020.</div>

    <p></p>
    <h2>Images from unsplash.com</h2>
    <div>Images on this website are provided by unsplash.com, "The internet’s source of freely-usable images.", "Powered by creators everywhere". We thankfully use images created by unsplash creators: Ali Yahya, Akson, Rebecca Campbell, Timon Studler, Tingey Injury Law Firm, Andy Li, Daniel Tausis, Scott Graham, and Zhang Kaiyv. </div>
    <div></div>

    <p></p>
    <h2>Website hosting by Google's firebase platform, firebase.google.com</h2>
    <div>This website is hosted on Google's Firebase platform, and created using Angular, TypeScript, and bootstrap frameworks.</div>

    <p></p>
    <h2>Policy questions and enforcement</h2>
    <div>EKIOSA Services is committed to protecting and correctly refering to items (such as frameworks or images) provided by others. If you have questions or comments about this policy, please contact us via one of the contact forms at our website, including specifying your email address, and we will respond to you soon.</div>
    <div class="container" style="background: none; position:relative; transform:translate(-1%,3%)">
        <message-form></message-form>
    </div>
    <p></p>
</div>

<div *ngIf="deviceDesktop">
    <english-bottom></english-bottom>
</div>
  
<div *ngIf="!deviceDesktop">
    <english-bottom-mob></english-bottom-mob>
</div>