<div class="card text-white border-warning border-1" >
    <img src="assets/images/daniel-tausis-loeqHoa1uWY-unsplash.jpg" class="card-img">
    <div class="card-img-overlay">
    <h4 class="card-title rounded" style="background: rgb(255, 128, 0);background: rgba(255, 128, 0, 0.8);color: #ffffff; text-align: center;position:relative;transform:translate(0%,0%)">Company insurances</h4>
    </div>
    <div class="card-header" style="background-color: peru;">
      Insurances manage company risks on goods, employees, other assets
    </div>
    <ul class="list-group list-group-flush bg-white text-dark">
      <li class="list-group-item">Determine the right set of insurances, to cover risks associated with your business</li>
      <li class="list-group-item">Balance insurance costs and coverage with the actual potential risks</li>
    </ul>
  </div>