<form style="max-width: 200pt;" (ngSubmit)="onSubmit()" #messageForm="ngForm">
    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="form-group" style="max-width: 230pt">
            <input type="text" formControlName="name" placeholder="Name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">Name is required</div>
            </div>
        </div>
        <div class="form-group" style="max-width: 200pt">
            <input type="text" formControlName="email" placeholder="Email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else</small> -->
            <div *ngIf="!submitted">
                <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else</small>
            </div>

            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
            </div>
        </div>
        <div class="form-group" style="max-width: 200pt">            
            <textarea class="form-control" id="InputMessage1" rows="5" placeholder="Message" formControlName="message" style="max-width: 200pt"></textarea>
        </div>
        <div class="form-group">
            <button type="submit" class="btn btn-primary">Get in touch</button>
        </div>
    </form>
</form>