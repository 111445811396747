import { Component } from '@angular/core';

@Component({
  selector: 'main-manderin',
  templateUrl: './manderin.component.html'
})
export class ManderinComponent 
{
  deviceDesktop = false;
  deviceAndroid = false;
  deviceIOS = false;

  ngOnInit()  
  {
    if(navigator.userAgent.match(/Android/i))
    {                                    
      this.deviceAndroid = true
    }
    else if(navigator.userAgent.match(/iPhone|iPad|iPod/i))
    {                                  
      this.deviceIOS = true
    }
    else
    {
      this.deviceDesktop = true
    }

    /* this.deviceDesktop = false
    this.deviceAndroid = true // DEBUG MOBILE */
  }
}