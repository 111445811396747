import { Component } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router  } from '@angular/router';

import { functions } from 'firebase';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'feedback-form',
  templateUrl: './feedbackForm.component.html'
})
@Injectable() export class FeedbackFormComponent {
  constructor(private http: HttpClient, private router: Router, private formBuilder: FormBuilder) { }
  log = "";
  submitted = false;
  registerForm: FormGroup;

  prevName = "";
  prevEmail = "";
  prevMessage = "";

  ngOnInit() 
  {
    this.registerForm = this.formBuilder.group(
    {
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        message: ['', []]
    });
  }
  
  get f() { return this.registerForm.controls; } // convenience getter for easy access to form fields

  onSubmit() 
  {
    this.submitted=true;

    if (this.registerForm.invalid) {
        return;
    }
    
    this.sendMessage();
  }

  sendMessage()
  {
    if (this.prevName == this.registerForm.value.name)
    {
      if (this.prevEmail == this.registerForm.value.email)
      {
        if (this.prevMessage == this.registerForm.value.message)
        {
          // prevent DOS attacks etc
          return;
        }
      }
    }
    this.prevName = this.registerForm.value.name;
    this.prevEmail = this.registerForm.value.email;
    this.prevMessage = this.registerForm.value.message;

    this.log = 
      "[Type:2][When:" + new Date().toLocaleString() +
      "][Website:" + this.router.url + 
      "][Name:" + this.registerForm.value.name +
      "][Email:" + this.registerForm.value.email +
      "][Message:" + this.registerForm.value.message + "]";

    const GMailService = functions().httpsCallable('GMailService');
    GMailService
    (
      {
        message: this.log
      }
    ).then(function(result)
    {
      console.log(result);
      const resultReturned = result.data.text;
    }
    ).catch(function(error)
    {
      console.log(error);
    });  
  }
}