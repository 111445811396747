<form style="max-width: 200pt;" (ngSubmit)="onSubmit()" #messageForm="ngForm">
    <div class="form-group" style="max-width: 230pt">
        <input type="text" class="form-control" id="email" placeholder="Email" required [(ngModel)]="message.email" name="email">
        <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else</small>
    </div>
    <div class="form-group" style="max-width: 200pt">
        <input type="text" class="form-control" id="name" placeholder="Name" [(ngModel)]="message.name" name="name">
    </div>
    <div class="form-group" style="max-width: 200pt">
        <textarea class="form-control" id="InputMessage1" rows="5" placeholder="Message" [(ngModel)]="message.message" name="message" style="max-width: 200pt"></textarea>
    </div>    
  
    <button type="submit" class="btn btn-primary">Get in touch</button>    
    <div *ngIf="submitted" style="color: white; position:absolute;transform:translate(150%,-130%)">Thank you!</div>
</form>