<div class="card text-white border-warning border-1">
    <img src="assets/images/scott-graham-5fNmWej4tAA-unsplash.jpg" class="card-img">
    <div class="card-img-overlay">
      <h4 class="card-title rounded" style="background: rgb(255, 128, 0);background: rgba(255, 128, 0, 0.8);color: #ffffff; text-align: center;">VAT consultancy</h4>
      <p class="card-text"></p>
    </div>
    <div class="card-header" style="background-color: peru">
      We provide you with various services related to Dutch VAT requirements
    </div>
    <ul class="list-group list-group-flush bg-white text-dark">
      <li class="list-group-item">VAT registration</li>
      <li class="list-group-item">Import VAT deferment license application</li>
      <li class="list-group-item">VAT returns</li>
    </ul>
  </div>