<div class='jumbotron jumbotron-fluid bg-info' style="padding-bottom:0.5%; margin-bottom: 0%;overflow: hidden; height: 150px">
  
    <button type="button" class="btn ml-3 float-left" style="background-color:transparent; position:relative;transform:translate(-1%,-35%)" routerLink="/english" ><img src="assets/images/Ekiosa.logo.white.JPG" height="70" alt=""></button>
    
    <p class="font-italic font-weight-bold bg-info" style="color:white; font-size: 22px; position:absolute;transform:translate(66%,0%)">One-stop-shop for doing business in the Netherlands</p>
  
    <button type="button" class="btn btn-primary ml-3 float-right" style="position:relative;transform:translate(-10%,0%)" routerLink="/english" >English <img src="assets/images/cif-gb.png" height="30" alt=""></button>
    <button type="button" class="btn btn-primary ml-3 float-right" style="position:relative;transform:translate(-11%,0%)" routerLink="/english" >Japanese <img src="assets/images/cif-jp.png" height="30" alt=""></button>
  
    <button type="button" class="btn btn-primary ml-3 float-right" 
      style="position:relative;transform:translate(-10%,0%)" 
      routerLink="/manderin"> 
      <img src="assets/images/cif-tw.png" height="30" alt=""> Chinese <img src="assets/images/cif-cn.png" height="30" alt="">
    </button>
</div>