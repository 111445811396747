<div class="card text-white border-warning border-1">
    <img src="assets/images/rebecca-campbell-wNGD6Qnb1xA-unsplash.jpg" class="card-img">
    <div class="card-img-overlay">
    <h4 class="card-title rounded" style="background: rgb(255, 128, 0);background: rgba(255, 128, 0, 0.8);color: #ffffff; text-align: center;">一站式商店</h4>
    </div>
    <div class="card-header" style="background-color: peru;">
      我们提供一站式服务，可为您提供各种不同类型的请求
    </div>
    <ul class="list-group list-group-flush bg-white text-dark">
      <li class="list-group-item">我们提供一站式服务，可为您提供各种不同类型的请求</li>
      <li class="list-group-item">我们在荷兰拥有丰富的业务专家网络，可以满足多种类型的需求</li>
    </ul>
  </div>  