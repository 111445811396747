<div class='container-fluid bg-info'>    
    <div class="d-flex flex-row">
      <button type="button" class="btn ml-3 float-left" style="background-color:transparent; position:relative;transform:translate(1%,7%)" routerLink="/english" ><img src="assets/images/Ekiosa.logo.white.JPG" height="70" alt=""></button>
    
      <div class="container" style="position:absolute;transform:translate(30%,10%)">
        <div class="p-2">©2020 EKIOSA Services. All rights reserved.</div>
        <div class="p-2">Email: info@ekiosa.eu</div>
      </div>
    </div>    

    <div class="d-flex flex-row-reverse" style="position:relative;transform:translate(-0%,-130%)">
      <div class="p-2"></div>
      <div class="p-2">
        <a routerLink="/englishDisclaimer" routerLinkActive="active" style="color: black;">   Disclaimer & Copyright</a>
      </div>
      <div class="p-2"></div>
      <div class="p-2">
        <a routerLink="/englishPrivacy" routerLinkActive="active" style="color: black;">   Privacy policy</a>
      </div>
      <div class="p-2"></div>
      <div class="p-2">
        <a routerLink="/englishCookie" routerLinkActive="active" style="color: black;">   Cookie policy </a>
      </div>
      <div class="p-2"></div>
      <div class="p-2">
        <a routerLink="/englishPoweredby" routerLinkActive="active" style="color: black;">   Powered by </a>
      </div>      
    </div>
  </div>