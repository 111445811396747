<div class="card text-white border-warning border-1">
    <img src="assets/images/cif-nl.png" style="height: 6em" class="card-img">
    <div class="card-img-overlay">
      <h4 class="card-title rounded" style="background: rgb(255, 128, 0);background: rgba(255, 128, 0, 0.8);color: #ffffff; text-align: center;position:relative; transform:translate(0%, -15%)">翻译与交流</h4>
    </div>
    <ul class="list-group list-group-flush text-dark">
      <li class="list-group-item" style="background-color:transparent;background-color: white;">了解，适应和翻译荷兰语言和文化</li>
      <li class="list-group-item">翻译成其他欧洲语言和文化</li>
      <li class="list-group-item">荷兰语翻译成中文或日文</li>
      <li class="list-group-item">市场营销和销售材料：网站，传单，白皮书，在线广告</li>
      <li class="list-group-item">法律材料：合同，政策</li>
      <li class="list-group-item">通讯：通过（例如）支持（每日）对话 电子邮件或在线会议</li>
    </ul>
  </div>