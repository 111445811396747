import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';

// manderin, main
import { ManderinComponent } from './manderin/manderin.component';
import { ManderinMobileComponent } from './manderin/manderin.mob.component';
import { ManderinDeskComponent } from './manderin/manderin.desk.component';

// manderin, top + bottom
import { ManderinTopComponent } from './shared/manderin/top.component';
import { ManderinTopMobComponent } from './shared/manderin/top.mob.component';
import { ManderinBottomComponent } from './shared/manderin/bottom.component';
import { ManderinBottomMobComponent } from './shared/manderin/bottom.mob.component';

// manderin, policy
import { ManderinPolicyPrivacyComponent } from './policies/manderin/privacy.component';
import { ManderinPolicyDisclaimerComponent } from './policies/manderin/disclaimer.component';
import { ManderinPolicyCookieComponent } from './policies/manderin/cookie.component';
import { ManderinPoweredByComponent } from './policies/manderin/poweredby.component';

// english, main
import { EnglishComponent } from './english/english.component';
import { EnglishMobileComponent } from './english/english.mob.component';
import { EnglishDeskComponent } from './english/english.desk.component';

// english, top + bottom
import { EnglishTopComponent } from './shared/english/top.component';
import { EnglishTopMobComponent } from './shared/english/top.mob.component';
import { EnglishBottomComponent } from './shared/english/bottom.component';
import { EnglishBottomMobComponent } from './shared/english/bottom.mob.component';

// english, policies
import { EnglishPolicyPrivacyComponent } from './policies/english/privacy.component';
import { EnglishPolicyDisclaimerComponent } from './policies/english/disclaimer.component';
import { EnglishPolicyCookieComponent } from './policies/english/cookie.component';
import { EnglishPoweredByComponent } from './policies/english/poweredby.component';

// forms
import { MessageFormComponent } from './shared/english/messageForm.component';
import { RequestFormComponent } from './shared/english/requestForm.component';
import { FeedbackFormComponent } from './shared/english/feedbackForm.component';
import { ServiceRequestComponent } from './shared/english/serviceRequest.component';

// forms Manderin
import { ManderinMessageFormComponent } from './shared/manderin/messageForm.component';
import { ManderinRequestFormComponent } from './shared/manderin/requestForm.component';
import { ManderinServiceRequestComponent } from './shared/manderin/serviceRequest.component';

// others
import { IpServiceComponent } from './shared/english/ipservice.component';
import { ExperimentsComponent } from './experiments/experiments.component';

// angular, firebase
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from 'src/environments/environment';
import * as firebase from 'firebase';
firebase.initializeApp(environment.firebase);

// english, cards
import { CardsCarousel } from './cards/english/cards.carousel';
import { CardGuide } from './cards/english/card.guide';
import { CardVat } from './cards/english/card.vat';
import { CardOfficeSpace } from './cards/english/card.office.space';
import { CardHrManagement } from './cards/english/card.hr.management';
import { CardPartnerManagement } from './cards/english/card.partner.management';
import { CardBusinessTravel } from './cards/english/card.business.travel';
import { CardSalesHunting } from './cards/english/card.sales.hunting';
import { CardTranslations } from './cards/english/card.translations';
import { CardFinancialRisk } from './cards/english/card.financial.risk';
import { CardOneStopShop } from './cards/english/card.one.stop.shop';
import { CardCompanyLegalAdvice } from './cards/english/card.company.legal.advice';
import { CardExportImport } from './cards/english/card.export.import';
import { CardCompanyInsurances } from './cards/english/card.company.insurances';
import { CardNetworkManagement } from './cards/english/card.network.management';
import { CardTeamSupport } from './cards/english/card.team.support';

// manderin, cards
import { ManderinCardsCarousel } from './cards/manderin/manderin.cards.carousel';
import { ManderinCardGuide } from './cards/manderin/card.guide';
import { ManderinCardVat } from './cards/manderin/card.vat';
import { ManderinCardOfficeSpace } from './cards/manderin/card.office.space';
import { ManderinCardHrManagement } from './cards/manderin/card.hr.management';
import { ManderinCardPartnerManagement } from './cards/manderin/card.partner.management';
import { ManderinCardBusinessTravel } from './cards/manderin/card.business.travel';
import { ManderinCardSalesHunting } from './cards/manderin/card.sales.hunting';
import { ManderinCardTranslations } from './cards/manderin/card.translations';
import { ManderinCardFinancialRisk } from './cards/manderin/card.financial.risk';
import { ManderinCardOneStopShop } from './cards/manderin/card.one.stop.shop';
import { ManderinCardCompanyLegalAdvice } from './cards/manderin/card.company.legal.advice';
import { ManderinCardExportImport } from './cards/manderin/card.export.import';
import { ManderinCardCompanyInsurances } from './cards/manderin/card.company.insurances';
import { ManderinCardNetworkManagement } from './cards/manderin/card.network.management';
import { ManderinCardTeamSupport } from './cards/manderin/card.team.support';

@NgModule({
  declarations: [
    AppComponent,

    // main pages
    EnglishComponent,
    EnglishMobileComponent,
    EnglishDeskComponent,
    ManderinComponent,
    ManderinMobileComponent,
    ManderinDeskComponent,
    
    // policies
    EnglishPolicyPrivacyComponent,
    EnglishPolicyDisclaimerComponent,
    EnglishPolicyCookieComponent,
    EnglishPoweredByComponent,
    ManderinPolicyPrivacyComponent,
    ManderinPolicyDisclaimerComponent,
    ManderinPolicyCookieComponent,
    ManderinPoweredByComponent,    
    
    // top + bottom
    EnglishTopComponent,
    EnglishTopMobComponent,
    EnglishBottomComponent,
    EnglishBottomMobComponent,
    ManderinTopComponent,
    ManderinTopMobComponent,
    ManderinBottomComponent,
    ManderinBottomMobComponent,

    IpServiceComponent,
    MessageFormComponent,
    RequestFormComponent,
    FeedbackFormComponent,
    ServiceRequestComponent,

    ManderinMessageFormComponent,
    ManderinRequestFormComponent,
    ManderinServiceRequestComponent,
    
    CardsCarousel,
    CardGuide,
    CardVat,
    CardOfficeSpace,
    CardHrManagement,
    CardPartnerManagement,
    CardBusinessTravel,
    CardSalesHunting,
    CardTranslations,
    CardFinancialRisk,
    CardOneStopShop,
    CardCompanyLegalAdvice,
    CardExportImport,
    CardCompanyInsurances,
    CardNetworkManagement,
    CardTeamSupport,

    ManderinCardsCarousel,
    ManderinCardGuide,
    ManderinCardVat,
    ManderinCardOfficeSpace,
    ManderinCardHrManagement,
    ManderinCardPartnerManagement,
    ManderinCardBusinessTravel,
    ManderinCardSalesHunting,
    ManderinCardTranslations,
    ManderinCardFinancialRisk,
    ManderinCardOneStopShop,
    ManderinCardCompanyLegalAdvice,
    ManderinCardExportImport,
    ManderinCardCompanyInsurances,
    ManderinCardNetworkManagement,
    ManderinCardTeamSupport,

    ExperimentsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule, // import HttpClientModule after BrowserModule
    RouterModule.forRoot([

      { path: 'english', component: EnglishComponent },
      { path: 'englishMobile', component: EnglishMobileComponent },
      { path: 'englishDesk', component: EnglishDeskComponent },

      { path: 'manderin', component: ManderinComponent },
      { path: 'manderinMobile', component: ManderinMobileComponent },
      { path: 'manderinDesk', component: ManderinDeskComponent },     

      { path: 'englishPrivacy', component: EnglishPolicyPrivacyComponent },
      { path: 'englishDisclaimer', component: EnglishPolicyDisclaimerComponent },
      { path: 'englishCookie', component: EnglishPolicyCookieComponent },
      { path: 'englishPoweredby', component: EnglishPoweredByComponent },

      { path: 'manderinPrivacy', component: ManderinPolicyPrivacyComponent },
      { path: 'manderinDisclaimer', component: ManderinPolicyDisclaimerComponent },
      { path: 'manderinCookie', component: ManderinPolicyCookieComponent },
      { path: 'manderinPoweredby', component: ManderinPoweredByComponent },
      
      { path: 'serviceRequest', component: ServiceRequestComponent },
      { path: 'manderinServiceRequest', component: ManderinServiceRequestComponent },
      
      { path: 'experiments', component: ExperimentsComponent },

      { path: '', redirectTo: 'english', pathMatch: 'full' },
      { path: '**', redirectTo: 'english', pathMatch: 'full' }

    ]), BrowserAnimationsModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
