import { Component } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient  } from '@angular/common/http';

@Component({
    selector: 'main-ipservice',
    templateUrl: './ipservice.component.html'
})

@Injectable({
    providedIn: 'root'
})

export class IpServiceComponent
{
    constructor(private http:HttpClient) { }  
    public getIPAddress()  
    {  
        return this.http.get("https://api.ipify.org/?format=json");  
    }  
}
