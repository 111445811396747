<div class="card text-white border-warning border-1" >
    <img src="assets/images/daniel-tausis-loeqHoa1uWY-unsplash.jpg" class="card-img">
    <div class="card-img-overlay">
    <h4 class="card-title rounded" style="background: rgb(80, 77, 75);background: rgba(255, 128, 0, 0.8);color: #ffffff; text-align: center;position:relative;transform:translate(0%,0%)">公司保险</h4>
    </div>
    <div class="card-header" style="background-color: peru;">
      保险管理商品，员工，其他资产的公司风险
    </div>
    <ul class="list-group list-group-flush bg-white text-dark">
      <li class="list-group-item">确定正确的保险套，以涵盖与业务相关的风险</li>
      <li class="list-group-item">平衡保险费用和承保范围与实际潜在风险</li>
    </ul>
  </div>