import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'english-cookie',
  templateUrl: './cookie.component.html'
})
export class EnglishPolicyCookieComponent 
{
  constructor(private router: Router) { }

  deviceDesktop = false;
  deviceAndroid = false;
  deviceIOS = false;

  ngOnInit() 
  {
    if(navigator.userAgent.match(/Android/i))
    {                                    
      this.deviceAndroid = true
    }
    else if(navigator.userAgent.match(/iPhone|iPad|iPod/i))
    {                                  
      this.deviceIOS = true
    }
    else
    {
      this.deviceDesktop = true
    }

    this.router.events.subscribe((evt) => 
    {
        window.scrollTo(0, 0)
    });
  }
}