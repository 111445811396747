<div class="card text-white border-warning border-1">
    <img src="assets/images/Schiphol.jpg" class="card-img">
    <div class="card-img-overlay">
    <h4 class="card-title rounded" style="background: rgb(255, 128, 0);background: rgba(255, 128, 0, 0.8);color: #ffffff; text-align: center;">Business travel</h4>
    </div>
    <div class="card-header" style="background-color: peru;">
      We use multiple Dutch travel agencies to find your optimal travel arrangements
    </div>
    <ul class="list-group list-group-flush bg-white text-dark">
      <li class="list-group-item">Search for the optimal solution</li>
      <li class="list-group-item">Keeping track of all administration</li>
      <li class="list-group-item">Active follow-up during traveling</li>
    </ul>
  </div>