import { Component } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageModel } from './messageModel';
import { Router  } from '@angular/router';

import { functions } from 'firebase';

@Component({
  selector: 'message-form',
  templateUrl: './messageForm.component.html'
})
@Injectable() export class MessageFormComponent {
  constructor(private http: HttpClient, private router: Router) { }
  log = "";
  message = new MessageModel('', '', '');
  submitted = false;

  prevName = "";
  prevEmail = "";
  prevMessage = "";

  onSubmit() 
  {
    this.submitted=true;
    this.sendMessage();
  }

  sendMessage()
  {
    if (this.prevName == this.message.name)
    {
      if (this.prevEmail == this.message.email)
      {
        if (this.prevMessage == this.message.message)
        {
          // prevent DOS attacks etc
          return;
        }
      }
    }
    this.prevName = this.message.name;
    this.prevEmail = this.message.email;
    this.prevMessage = this.message.message;

    this.log = 
      "[Type:2][When:" + new Date().toLocaleString() +
      "][Website:" + this.router.url + 
      "][Name:" + this.message.name +
      "][Email:" + this.message.email +
      "][Message:" + this.message.message + "]";

    const GMailService = functions().httpsCallable('GMailService');
    GMailService
    (
      {
        message: this.log
      }
    ).then(function(result)
    {
      console.log(result);
      const resultReturned = result.data.text;
    }
    ).catch(function(error)
    {
      console.log(error);
    });  
  }
}