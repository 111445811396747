<div class="card text-white border-warning border-1">
    <img src="assets/images/finance2.jpg" class="card-img">
    <div class="card-img-overlay">
      <h4 class="card-title rounded" style="background: rgb(255, 128, 0);background: rgba(255, 128, 0, 0.8);color: #ffffff; text-align: center;">Financial risk management</h4>
    </div>
  
    <ul class="list-group list-group-flush bg-white text-dark">
      <li class="list-group-item">Consider receiving revenue in Euros</li>
      <li class="list-group-item">Any market turbulance, such as the Covid crisis, can quickly change exchange rates</li>
      <li class="list-group-item">For this and other financial scenarios, risk management can prevent substantial losses and/or increase your profits</li>
    </ul>
  </div>