<div class="card text-white border-warning border-1">
    <img src="assets/images/tingey-injury-law-firm-yCdPU73kGSc-unsplash.jpg" class="card-img">
    <div class="card-img-overlay">
    <h4 class="card-title rounded" style="background: rgb(255, 128, 0);background: rgba(255, 128, 0, 0.8);color: #ffffff; text-align: center;position:relative;transform:translate(0%,0%)">Company legal advice</h4>
    </div>
    <div class="card-header" style="background-color: peru;">
      Use the experts in our network to defend company rights and follow necessary obligations
    </div>
    <ul class="list-group list-group-flush bg-white text-dark">
      <li class="list-group-item">We cover Dutch law & regulations</li>
      <li class="list-group-item">Regarding company & employee legal matters</li>
      <li class="list-group-item">Company registration</li>
      <li class="list-group-item">Dutch and European tax regulations</li>
      <li class="list-group-item">Privacy laws</li>
      <li class="list-group-item">Various types of personnel contracts</li>        
      <li class="list-group-item">Personnel laws</li>
      <li class="list-group-item">Various other legal advice</li>
    </ul>
  </div>