<div class="card text-white border-warning border-1">
    <img src="assets/images/koning.jpg" class="card-img">
    <div class="card-img-overlay">
    <h4 class="card-title rounded" style="background: rgb(255, 128, 0);background: rgba(255, 128, 0, 0.8);color: #ffffff; text-align: center;position:relative;transform:translate(0%,0%)">网络管理</h4>
    </div>
    <div class="card-header" style="background-color: peru;">
      拥有正确的本地连接可以为您的竞争优势带来变化
    </div>
    <ul class="list-group list-group-flush bg-white text-dark">
      <li class="list-group-item">我们可以指导您与对您的业务至关重要的政府官员进行游说</li>
      <li class="list-group-item">政府（和其他官员）的支持可以将您的业务推向正确的方向</li>
    </ul>
  </div>