import { Component } from '@angular/core';

@Component({
  selector: 'main-experiments',
  templateUrl: './experiments.component.html'
})
export class ExperimentsComponent
{
  deviceDesktop = false;
  deviceAndroid = false;
  deviceIOS = false;

  ngOnInit() 
  {
    if(navigator.userAgent.match(/Android/i))
    {                                    
      this.deviceAndroid = true
    }
    else if(navigator.userAgent.match(/iPhone|iPad|iPod/i))
    {                                  
      this.deviceIOS = true
    }
    else
    {
      this.deviceDesktop = true
    }
  }
}