<english-top></english-top>

<div class='jumbotronCurtainDesk'>
  <!-- Guide -->
  <div style="width:29rem;position:relative;transform:translate(4%,5%)">
    <card-guide></card-guide>
  </div>
  <!-- Message Form -->
  <div class="jumbotron" style="background:none; position:absolute; transform:translate(142%,-98%)">    
    <message-form></message-form>
  </div>
  <!-- VAT -->
  <div style="z-index:3; width:18rem; position:relative; transform:translate(6%,10%)">
    <card-vat></card-vat>
  </div>
  <!-- HR management -->
  <div style="z-index:3; width:18rem; position:relative; transform:translate(115%,-50%)">
    <card-hr-management></card-hr-management>
  </div>
  <!-- Partner management -->
  <div style="z-index:3; width:18rem; position:relative; transform:translate(225%,-210%)">
    <card-partner-management></card-partner-management>
  </div>
  <!-- Business travel -->
  <div style="z-index:3; width:18rem; position:relative;transform:translate(335%,-290%)">
    <card-business-travel></card-business-travel>
  </div>
  <!-- Translations & Communication -->
  <div style="z-index:3; width:18rem; position:relative;transform:translate(6%,-262%)">
    <card-translations></card-translations>
  </div>
</div>

<div class='jumbotronFlag'>
  <!-- Flag -->
  <div class='jumbotron' style="height:200px; background-color:darkred; position:relative;transform:translate(0%,-60%)"></div>
  <div class='jumbotron' style="height:200px; background-color:white"></div>
  <div class='jumbotron' style="height:200px; background-color:royalblue; position:relative;transform:translate(0%,-50%)"></div>

  <!-- One stop shop -->
  <div style="z-index:3; width: 18rem; position:relative;transform:translate(225%,-65%)">
    <card-one-stop-shop></card-one-stop-shop>
  </div>  
</div>

<div class='jumbotronWindmills'>
  <!-- Sales hunting -->
  <div style="z-index:3; width:18rem; position:relative;transform:translate(6%,31%)">
    <card-sales-hunting></card-sales-hunting>
  </div>
  <!-- Financial risk management -->
  <div style="z-index:3; width:18rem; position:relative; transform:translate(115%, -130%)">
    <card-financial-risk></card-financial-risk>
  </div>
  <!-- New opportunities are waiting -->
  <div class="card text-white bg-info mb-3" style="max-width: 18rem; position:relative;transform:translate(335%,-291%)">   
    <div class="card-body">
      <h5 class="card-title">New opportunities are waiting...</h5>
      <p class="card-text">Reshuffling after Covid</p>
      <p class="card-text">With a economic recession, weaker players need to give way</p>
      <p class="card-text">Governments facilitate massive funding</p>
      <p class="card-text">New business opportunities are waiting for new players to be picked up</p>
      <p class="card-text">Now its your turn...let's get started</p>
    </div>
  </div>

  <button type="button" class="btn btn-lg btn-block" style="z-index: 3; position:relative;transform:translate(0%,-2435%);height: 30px;background-color:darkorange;"></button>
</div>

<div class='jumbotronUpdates'>
  <!-- Videos -->
  <div class="container" style="position:absolute;transform:translate(0%,5%)">
    <div class="row">
      <div class="col-sm-6">
        <iframe width="450" height="315" src="https://www.youtube.com/embed/uJoeHcu6IUg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div class="col-sm-6" style="position:relative;transform:translate(-15%,0%)">
        <iframe width="450" height="315" src="https://www.youtube.com/embed/r3DbGxC0EM8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
    <p></p>
    <div class="row">
      <div class="col-sm-6" style="position:relative;transform:translate(0%,-6%)">
        <iframe width="450" height="315" src="https://www.youtube.com/embed/LOn6OFYo-iA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div class="col-sm-6" style="position:relative;transform:translate(-15%,-6%)">
        <iframe width="450" height="315" src="https://www.youtube.com/embed/2fYlc6ikWE0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
    <p></p>
    <div class="row">
      <div class="col-sm-6" style="position:relative;transform:translate(0%,-6%)">
        <iframe width="450" height="315" src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FTaiwan.in.the.Netherlands%2Fvideos%2F1035350356899662%2F&show_text=false&width=560" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>
      </div>
      <div class="col-sm-6" style="position:absolute;transform:translate(85%,-16%)">
        <iframe width="450" height="315" src="https://www.youtube.com/embed/okve-cI0P10" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>

  <!-- Our updates -->
  <div class="card text-white bg-info mb-3" style="z-index:3; max-width:18rem; position:relative;transform:translate(335%,-62%)">   
    <div class="card-body">
      <h5 class="card-title">Timely customized updates</h5>
      <p class="card-text">Once you start to use our services</p>
      <p class="card-text">We automatically provide you with customized updates and targeted alerts</p>
      <p class="card-text">These fit with your requested services and your type of business</p>
      <p class="card-text">Updates on e.g. changed Dutch laws or regulations</p>
      <p class="card-text">Updates on Covid related matters such as government support</p>
      <p class="card-text">Alerts on e.g. procedural deadlines</p>
      <p class="card-text">Various other updates that fit with your business</p>
      <p class="card-text">All described in one customized newsletter, and fully configurable by you</p>
    </div>
  </div>
  
  <div class="card text-white bg-info mb-3" style="max-width: 18rem; position:relative;transform:translate(335%,-90%)">   
    <div class="card-body">
      <h5 class="card-title">Support us to support you</h5>
      <p class="card-text">We add services, based on your feedback</p>
      <p class="card-text">Current service offers are based on existing client feedback</p>
      <p class="card-text">We focus on companies from Taiwan, China, and Japan, since we have existing expertise about their specific service requests</p>
      <p class="card-text">We welcome feedback from any organization doing business in the Netherlands</p>
    </div>
  </div>

  <div class="jumbotron" style="z-index:3; background:none; position:absolute; transform:translate(292%,-85%)">    
    <message-form></message-form>
  </div>

  <div class="alert alert-success bg-info text-white" style="position:relative;transform:translate(0%,15%)" role="alert" >
    <hr> 
    <h4 class="alert-heading">Service request: please use our <a routerLink="/serviceRequest" class="alert-link"> service request form</a> to describe your request</h4>
    <hr>    
  </div>
</div>

<div class="jumbotronService">
  <!-- Company legal advice -->
  <div style="width: 18rem; position:relative;transform:translate(5%,5%)">
    <card-company-legal-advice></card-company-legal-advice>
  </div>

  <!-- Export/Import -->
  <div style="width: 18rem;;transform:translate(110%,-188%)">
    <card-export-import></card-export-import>
  </div>

  <!-- Office space -->
  <div style="width: 18rem; position:relative;transform:translate(110%,-137%)">
    <card-office-space></card-office-space>
  </div>

  <!-- Network management -->
  <div style="width: 18rem; position:relative;transform:translate(220%,-331%)">
    <card-network-management></card-network-management>
  </div>

  <!-- Team support -->
  <div style="width: 18rem; position:relative;transform:translate(220%,-310%)">
    <card-team-support></card-team-support>
  </div>

   <!-- Company insurances -->
   <div style="width: 18rem; position:relative;transform:translate(5%,-395%)">
    <card-company-insurances></card-company-insurances>
  </div>
</div>

<english-bottom></english-bottom>