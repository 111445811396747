<div class="card text-white border-warning border-1">
    <img src="assets/images/timon-studler-ABGaVhJxwDQ-unsplash.jpg" class="card-img">
    <div class="card-img-overlay">
      <h4 class="card-title rounded" style="background: rgb(255, 128, 0);background: rgba(255, 128, 0, 0.8);color: #ffffff; text-align: center;">合作伙伴管理</h4>
    </div>
    <div class="card-header" style="background-color: peru">
      本地合作伙伴管理完全在我们荷兰人手中
    </div>
    <ul class="list-group list-group-flush bg-white text-dark">
      <li class="list-group-item">本地合作伙伴发现</li>
      <li class="list-group-item">谈判和合同事宜</li>
      <li class="list-group-item">主动沟通</li>
      <li class="list-group-item">管理和跟进</li>
    </ul>
  </div>