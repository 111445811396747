<div class="card text-white border-warning border-1">
    <img src="assets/images/rebecca-campbell-wNGD6Qnb1xA-unsplash.jpg" class="card-img">
    <div class="card-img-overlay">
    <h4 class="card-title rounded" style="background: rgb(255, 128, 0);background: rgba(255, 128, 0, 0.8);color: #ffffff; text-align: center;">One stop shop</h4>
    </div>
    <div class="card-header" style="background-color: peru;">
      We provide a one-stop-shop, to support you with a broad range of different types of requests
    </div>
    <ul class="list-group list-group-flush bg-white text-dark">
      <li class="list-group-item">We are familiar with a wide range of requests, from existing clients</li>
      <li class="list-group-item">Our rich network of business experts in the Netherlands is well choosen to fit many types of requests</li>
    </ul>
  </div>  