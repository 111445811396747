<div class="card text-white border-warning border-1">
    <img src="assets/images/sales.jpg" class="card-img">
    <div class="card-img-overlay">
      <h4 class="card-title rounded" style="background: rgb(255, 128, 0);background: rgba(255, 128, 0, 0.7);color: #ffffff; text-align: center;position:relative; transform:translate(0%, -30%)">寻找销售</h4>
    </div>
    
    <ul class="list-group list-group-flush bg-white text-dark">
      <li class="list-group-item">飞牛，或您出售的其他物品</li>
      <li class="list-group-item">您可以使用我们成熟的销售狩猎技能和经验</li>
    </ul>
  </div>