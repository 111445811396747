import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router  } from '@angular/router';
import { functions } from 'firebase';

@Component({
  selector: 'main-english-mob',
  templateUrl: './english.mob.component.html'
})
export class EnglishMobileComponent {
  constructor(private router: Router, private formBuilder: FormBuilder) { }
  log = "";
  submitted = false;
  registerForm: FormGroup;

  serviceTypesList = ['HR management', 'Office space',
            'Partner management', 'VAT consultancy', 'Business travel',
            'Sales hunting', 'Translations & communication',
            'Financial risk management', 'Company legal advice', 'Export/Import',
            'Network management', 'Company insurances', 'Team support',];

  prevName = "";
  prevEmail = "";
  prevMessage = "";
  serviceType = "HR management";
  get f() { return this.registerForm.controls; } // convenience getter for easy access to form fields

  ngOnInit() 
  {
    this.registerForm = this.formBuilder.group(
    {
      serviceType: ['', Validators.required],
      description: ['', [Validators.required, Validators.minLength(2)]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]        
    });

    this.registerForm.value.serviceType = "HR management";
  }

  onSubmit() 
  {
    this.submitted=true;

    if (this.registerForm.invalid) {
        return;
    }
    
    this.sendMessage();
  }

  sendMessage()
  {
    if (this.prevName == this.registerForm.value.lastName)
    {
      if (this.prevEmail == this.registerForm.value.email)
      {
        if (this.prevMessage == this.registerForm.value.message)
        {
          // prevent DOS attacks etc
          return;
        }
      }
    }
    this.prevName = this.registerForm.value.lastName;
    this.prevEmail = this.registerForm.value.email;
    this.prevMessage = this.registerForm.value.message;

    this.log = 
      "[Type:3][When:" + new Date().toLocaleString() +
      "][Website:" + this.router.url + 
      "][Name:" + this.registerForm.value.firstName + " " + this.registerForm.value.lastName +
      "][Email:" + this.registerForm.value.email +
      "][Message:" + this.registerForm.value.description +
      "][ServiceType:" + this.registerForm.value.serviceType + "]";

    const GMailService = functions().httpsCallable('GMailService');
    GMailService
    (
      {
        message: this.log
      }
    ).then(function(result)
    {
      console.log(result);
      const resultReturned = result.data.text;
    }
    ).catch(function(error)
    {
      console.log(error);
    });  
  }
}