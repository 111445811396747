<div class="alert alert-success " style="max-width: 900px;" role="alert" >
    <h4 class="alert-heading">Service request</h4>
    <p>Please use this service request form to describe your request.</p>
    <hr>
    <p class="mb-0">We will respond to you soon and provide you with a clear costs overview first, before we start implementing your request. Our competing service costs start from 50 Euro (excl. VAT) for basic requests.</p>
</div>

<form style="max-width: 250pt;" (ngSubmit)="onSubmit()" #messageForm="ngForm">
    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        
        <div class="form-group">
            <select class="form-control" id="serviceType" formControlName="serviceType" required [(ngModel)]="serviceType" name="serviceType">
              <option *ngFor="let st of serviceTypesList" [value]="st">{{st}}</option>
            </select>
        </div>

        <div class="form-group">
            <textarea class="form-control" id="description" formControlName="description" name="description" rows="10" placeholder="Detailed description" [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
            <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                <div *ngIf="f.description.errors.required">A detailed description of your service request is required</div>
            </div>
        </div>

        <div class="form-group">
            <input type="text" formControlName="firstName" placeholder="First Name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                <div *ngIf="f.firstName.errors.required">First Name is required</div>
            </div>
        </div>
        <div class="form-group">
            <input type="text" formControlName="lastName" placeholder="Last Name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
            <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                <div *ngIf="f.lastName.errors.required">Last Name is required</div>
            </div>
        </div>
        <div class="form-group">
            <input type="text" formControlName="email" placeholder="Email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
            </div>
        </div>
        
        <div class="form-group">
            <button class="btn btn-primary">Submit</button>
            <div *ngIf="submitted" style="color: white; position:absolute;transform:translate(40%,-130%)">Thank you, we will reply soon!</div>
        </div>
    </form>
</form>