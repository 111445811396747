<div class="card text-white border-warning border-1">
    <img src="assets/images/tingey-injury-law-firm-yCdPU73kGSc-unsplash.jpg" class="card-img">
    <div class="card-img-overlay">
    <h4 class="card-title rounded" style="background: rgb(255, 128, 0);background: rgba(255, 128, 0, 0.8);color: #ffffff; text-align: center;position:relative;transform:translate(0%,0%)">公司法律咨询</h4>
    </div>
    <div class="card-header" style="background-color: peru;">
      使用我们网络中的专家捍卫公司权利并遵守必要的义务
    </div>
    <ul class="list-group list-group-flush bg-white text-dark">
      <li class="list-group-item">我们涵盖了荷兰法律法规</li>
      <li class="list-group-item">关于公司和员工的法律事务</li>
      <li class="list-group-item">公司注册</li>
      <li class="list-group-item">荷兰和欧洲税收法规</li>
      <li class="list-group-item">隐私法</li>
      <li class="list-group-item">各类人事合同</li>        
      <li class="list-group-item">人事法</li>
      <li class="list-group-item">其他各种法律意见</li>
    </ul>
  </div>