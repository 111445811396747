<div class='bg-info' style="height:85px;padding-bottom:0.5%; margin-bottom:0%; overflow:visible;">
  <!-- solve spacing/moving issues: make the height bigger -->
  <!-- logo -->
  <button type="button" class="btn ml-3 float-left"
      style="position:relative;transform:translate(-5%,0%)"
      routerLink="/english">
      <img src="assets/images/Ekiosa.logo.white.JPG" height="70">
  </button>
</div>

<div class='bg-info' style="height:40px;padding-bottom:0.5%; margin-bottom:0%; overflow:visible;">
  <!-- Chinese -->
  <span class="badge badge-primary ml-3"
    style="position:relative;transform:translate(12%,0%)"
    routerLink="/manderin"> 
    <img src="assets/images/cif-tw.png" height="25"> Chinese
    <img src="assets/images/cif-cn.png" height="25">
  </span>
  <!-- Japanese -->
  <span class="badge badge-primary ml-3"
  style="position:relative;transform:translate(20%,0%)"
    routerLink="/english"> Japanese
    <img src="assets/images/cif-jp.png" height="25">
  </span> 
</div>
