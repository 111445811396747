<div *ngIf="deviceDesktop">
    <english-top></english-top>
</div>  
<div *ngIf="!deviceDesktop">
    <english-top-mob></english-top-mob>
</div>

<div class="container">

    <h1>Disclaimer & Copyright statement</h1>

    <div>This disclaimer & copyright policy statement was last updated on 6 October 2020.</div>

    <p></p>
    <h2>Overview</h2>
    <div>By using this website, you agree to be bound by this disclaimer & copyright. If you do not agree to it, you are prohibited from using this website and you must immediately cease using it.</div>
    <p></p>
    <h2>Purpose of the website - information only</h2>
    <div>The information on this website is intended to furnish users with general information on matters that they may find to be of interest. While every effort has been made to offer current and accurate information, errors can occur. Furthermore, this website may contain references to certain laws and regulations. Laws and regulations will change over time and should be interpreted only in light of particular circumstances and the date the article was published. This website, and content available within it, is for informational purposes only. Neither this website nor the content available within it constitutes professional advice, and neither should be relied upon by you or any third party. Before making any decision or taking any action, you should consult with professional advisers.</div>
    <p></p>
    <h2>Disclaimers and limitation of liability</h2>
    <div>This website (including its content or other parts thereof) contains only general information and we do not provide any professional services or advice through this website. Before making any decisions or taking any steps that may affect your finances or business, you should consult a qualified professional advisor.
    </div>
    <p></p>
    <div>This website is provided "As is" and we make no representations or warranties, express or implied. Without prejudice to the foregoing, we do not warrant that this website is secure, error free, free of viruses or malicious code, or that it meets certain performance or quality criteria. We expressly disclaim all implied warranties, including warranties of merchantability, proprietary rights, fitness for a particular purpose, non-infringement, compatibility, security and accuracy.</div>
    <p></p>
    <div>You use this website at your own risk and you accept full responsibility and risk of loss arising from such use, including loss of services or data. We are not liable for any direct, indirect, special or incidental damage, consequential damage or punitive damages, whether arising in contract, statutory provision, wrongful act (including negligence), or otherwise, in connection with or arising out of the use of this website, even if we knew or should have known about the possibility of such damage (or damages).</div>
    <p></p>
    <div>Certain links on this website may lead to websites, resources or tools maintained by third parties over whom we have no control, including websites maintained by individual employees of EKIOSA Services. Without prejudice to the foregoing, we make no express or implied representations or warranties about those websites, sources and tools, and links to them do not imply our endorsement of them or their content.
    </div>
    <p></p>
    <div>The above disclaimers and limitations of liability apply not only to us, but also to our staff members.
    </div>
    <p></p>
    <div>The above disclaimers and limitations of liability apply to the fullest extent permitted by law, whether arising in contract, statutory provision, wrongful act (including negligence) or otherwise.
    </div>
    
    <p></p>
    <h2>Use of content</h2>
    <div>Unless otherwise indicated at the relevant location and provided that you fully comply with the provisions of this disclaimer & copyright, you may view, copy, print and distribute (but not modify) the content of this website, provided that (i) such intended use is for informational and non‑commercial purposes only, and (ii) any copy of the content you make includes the copyright notice or other attribution associated with the content.
        You are prohibited from copying or using any software, proprietary process or technology incorporated or described on this website.        
        Each time you access and use this website, you will comply with all applicable laws.        
        You accept that we may use your personal data and other information in accordance with the provisions of our privacy statement and the cookie notice, which are part of this reference. You hereby agree to the provisions of our privacy statement and cookie notice, including any obligations imposed on you therein.</div>
    <p></p>
    <h2>Intellectual property rights</h2>
    <div>Unless otherwise indicated, EKIOSA Services takes care of the content of this website.
        This website and its content are protected under the laws of the Netherlands and/or other countries (including copyright and trademark laws). We and our licensors reserve all rights not expressly granted in this disclaimer & copyright.
        "EKIOSA Services", the EKIOSA Services logo, and local language versions of the aforementioned trademarks, as well as certain product names appearing on this website, are trademarks or registered trademarks.
        Unless expressly stated in this disclaimer & copyright notice, you will refrain from using the name "EKIOSA Services", the EKIOSA Services logo, or local language versions of the said trademarks, either alone or in combination with other words or design elements. You are prohibited from using any of the aforementioned names, brands and logos in press releases, advertisements, or other advertising or marketing materials or media, whether written, oral, digital, visual or in any other form, unless expressly authorized in writing by EKIOSA Services. Any requests for such written permission should be made through “contact” on this website.
        References on this website to trademarks of other parties are for identification purposes only and do not constitute an endorsement by those other parties of this website or its contents. This disclaimer & copyright does not grant you any rights to use other parties' trademarks.</div>

    <p></p>
    <h2>Additional terms and conditions</h2>
    <div>If any part of this disclaimer & copyright is invalid or unenforceable at law in one or more jurisdictions, then(i) it will be construed differently in that jurisdiction to the extent legally permissible, so as to approximate as closely as possible its intent, while the other provisions of this disclaimer & copyright will remain in full force and effect, and(ii) this disclaimer & copyright will remain in full force and effect in every other jurisdiction.</div>
    <p></p>
    <div>We may revise this disclaimer & copyright at any time, at our sole discretion, by posting the revised version under the “Disclaimer & Copyright” link (i.e. this webpage, on which you are now located) or elsewhere on this website. The revised version will come into effect immediately after it has been posted, unless explicitly stated otherwise by us. It is your responsibility to keep informed of revised versions of the disclaimer & copyright by regularly checking this webpage. Your continued use of this website after modification of this disclaimer & copyright means that you agree to the revised disclaimer & copyright.</div>

    <p></p>
    <h2></h2>
    <div></div>
    
</div>

<div *ngIf="deviceDesktop">
    <english-bottom></english-bottom>
</div>
  
<div *ngIf="!deviceDesktop">
    <english-bottom-mob></english-bottom-mob>
</div>