export class MessageModel 
{
    constructor
    (
      public name: string,
      public email: string,
      public message: string
    ) 
    {
    }  
  }