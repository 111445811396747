<div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">    
    <div class="carousel-inner">
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(2%,0%)">
        <card-one-stop-shop></card-one-stop-shop>     
      </div>
      <div class="carousel-item active" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <card-vat></card-vat>
      </div>
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <card-office-space></card-office-space>
      </div>
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <card-hr-management></card-hr-management>      
      </div>
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <card-partner-management></card-partner-management>      
      </div>
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <card-business-travel></card-business-travel>      
      </div>
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <card-sales-hunting></card-sales-hunting>      
      </div>
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <card-translations></card-translations>     
      </div>
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <card-financial-risk></card-financial-risk>     
      </div>
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <card-company-legal-advice></card-company-legal-advice>     
      </div>
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <card-export-import></card-export-import>    
      </div>
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <card-company-insurances></card-company-insurances>    
      </div>
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <card-network-management></card-network-management>    
      </div>
      <div class="carousel-item" style="width: 18rem; position:relative; transform:translate(20%,0%)">
        <card-team-support></card-team-support>    
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>