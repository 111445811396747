<div class="card text-white border-warning border-1">
    <img src="assets/images/timon-studler-ABGaVhJxwDQ-unsplash.jpg" class="card-img">
    <div class="card-img-overlay">
      <h4 class="card-title rounded" style="background: rgb(255, 128, 0);background: rgba(255, 128, 0, 0.8);color: #ffffff; text-align: center;">Partner management</h4>
    </div>
    <div class="card-header" style="background-color: peru">
      Local partner management fits exactly in our Dutch hands
    </div>
    <ul class="list-group list-group-flush bg-white text-dark">
      <li class="list-group-item">Local partner discovery</li>
      <li class="list-group-item">Negotiation & contract matters</li>
      <li class="list-group-item">Active communication</li>
      <li class="list-group-item">Management and follow-up</li>
    </ul>
  </div>