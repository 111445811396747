import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router  } from '@angular/router';
import { functions } from 'firebase';

@Component({
  selector: 'main-manderin-mob',
  templateUrl: './manderin.mob.component.html'
})
export class ManderinMobileComponent {
  constructor(private router: Router, private formBuilder: FormBuilder) { }
  log = "";
  submitted = false;
  registerForm: FormGroup;

  serviceTypesList = ['人力资源管理', '办公空间',
            '合作伙伴管理', '增值税咨询', '商务旅行',
            '寻找销售', '翻译与交流',
            '财务风险管理', '公司法律咨询', '进出口',
            '网络管理', '公司保险', '团队支持',];

  prevName = "";
  prevEmail = "";
  prevMessage = "";
  serviceType = "人力资源管理";
  get f() { return this.registerForm.controls; } // convenience getter for easy access to form fields

  ngOnInit() 
  {
    this.registerForm = this.formBuilder.group(
      {
        serviceType: ['', Validators.required],
        description: ['', [Validators.required, Validators.minLength(2)]],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]        
      });
  
      this.registerForm.value.serviceType = "HR management";
  }

  onSubmit() 
  {
    this.submitted=true;

    if (this.registerForm.invalid) {
        return;
    }
    
    this.sendMessage();
  }

  sendMessage()
  {
    if (this.prevName == this.registerForm.value.lastName)
    {
      if (this.prevEmail == this.registerForm.value.email)
      {
        if (this.prevMessage == this.registerForm.value.message)
        {
          // prevent DOS attacks etc
          return;
        }
      }
    }
    this.prevName = this.registerForm.value.lastName;
    this.prevEmail = this.registerForm.value.email;
    this.prevMessage = this.registerForm.value.message;

    this.log = 
      "[Type:3][When:" + new Date().toLocaleString() +
      "][Website:" + this.router.url + 
      "][Name:" + this.registerForm.value.firstName + " " + this.registerForm.value.lastName +
      "][Email:" + this.registerForm.value.email +
      "][Message:" + this.registerForm.value.description +
      "][ServiceType:" + this.registerForm.value.serviceType + "]";

    const GMailService = functions().httpsCallable('GMailService');
    GMailService
    (
      {
        message: this.log
      }
    ).then(function(result)
    {
      console.log(result);
      const resultReturned = result.data.text;
    }
    ).catch(function(error)
    {
      console.log(error);
    });  
  }
}