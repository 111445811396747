<manderin-top-mob></manderin-top-mob>
  
<div class='jumbotronCurtain'>
  <!-- card guide -->
  <label></label>
  <div style="width:20rem; position:relative;transform:translate(3%,0%)">
    <manderin-card-guide></manderin-card-guide>
  </div>
  <label></label>
</div>
  
<div class='jumbotronCardsMobile'>
  <!-- services list -->
  <div style="height:340px">
    <h4 style="background-color: peru; color: white; height:35px">->服务:</h4>
    <h6>
      <option *ngFor="let st of serviceTypesList" [value]="st">{{st}}</option>
    </h6>
  </div>
</div>
  
<div class='jumbotronServicesMobile'>
  <!-- message -->
  <h4 style="background-color: peru; color: white; height:35px">->您的反馈:</h4>
  <div style="max-width:260px;position:relative;transform:translate(8%,0%)">
    <manderin-message-form></manderin-message-form>
  </div>
  <label></label>
</div>

<div class='jumbotronCardsCarouselMobile'>
  <!-- cards carousel -->
  <h4 style="background-color: peru; color: white; height:35px">->一站式商店:</h4>  
  <div>
    <manderin-cards-carousel></manderin-cards-carousel>
  </div>
  <label></label>
</div>
  
<div class='jumbotronCardsMobile' style="height:760px">
  <!-- service request -->
  <h4 style="background-color: peru; color: white; height:35px">->服务申请表:</h4>
  <label></label>
  <div class="alert alert-success" role="alert" >
    <p class="mb-0">在我们开始执行您的请求之前，我们会尽快给您答复，并为您提供清晰的费用概览。 对于基本要求，我们的竞争服务费用从50欧元（不含增值税）开始。</p>
  </div>
  
  <form style="max-width:260px;position:relative;transform:translate(8%,0%)" (ngSubmit)="onSubmit()" #messageForm="ngForm">
    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        
        <div class="form-group">
            <select class="form-control" id="serviceType" formControlName="serviceType" required [(ngModel)]="serviceType" name="serviceType">
              <option *ngFor="let st of serviceTypesList" [value]="st">{{st}}</option>
            </select>
        </div>

        <div class="form-group">
            <textarea class="form-control" id="description" formControlName="description" name="description" rows="10" placeholder="详细说明" [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
            <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                <div *ngIf="f.description.errors.required">需要您的服务请求的详细说明</div>
            </div>
        </div>

        <div class="form-group">
            <input type="text" formControlName="firstName" placeholder="名字" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                <div *ngIf="f.firstName.errors.required">名字为必填项</div>
            </div>
        </div>
        <div class="form-group">
            <input type="text" formControlName="lastName" placeholder="姓" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
            <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                <div *ngIf="f.lastName.errors.required">必须填写姓氏</div>
            </div>
        </div>
        <div class="form-group">
            <input type="text" formControlName="email" placeholder="电子邮件" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">电子邮件为必填项</div>
                <div *ngIf="f.email.errors.email">电子邮件必须是有效的电子邮件地址</div>
            </div>
        </div>
        
        <div class="form-group">
            <button class="btn btn-primary">提交</button>
            <div *ngIf="submitted" style="color: white; position:absolute;transform:translate(40%,-130%)">谢谢，我们会尽快回复</div>
        </div>
    </form>
  </form>
</div>
  
<manderin-bottom-mob></manderin-bottom-mob>