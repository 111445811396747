import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { IpServiceComponent } from './shared/english/ipservice.component';
import { Title, Meta } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Router  } from '@angular/router';

// TODO analytics
/* declare let gtag: Function;
import { NavigationEnd} from '@angular/router'; */

import { functions } from 'firebase';

@Component({
  selector: 'jpg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
@Injectable() export class AppComponent implements OnInit{
  title = 'EKIOSA Services';
  constructor(
    private titleService: Title,
    private metaService: Meta, 
    private ip:IpServiceComponent,
    private http: HttpClient,
    private router: Router)
  {
    // TODO analytics
    /* this.router.events.subscribe(event => 
    {
      if(event instanceof NavigationEnd)
      {
          gtag('config', 'G-FEX5VQVM26', 
            {
              'page_path': event.urlAfterRedirects
            });
       }
    }) */
  }

  ipAddress = '';
  ipAddressSend = ' ';
  log = "";
  device = "";

  ngOnInit()  
  {  
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'keywords', content: 'EKIOSA Services Consultancy business Netherlands Dutch Holland'},
      {name: 'description', content: 'Use our knowledge, experience, and network to do business in the Netherlands'},
      {name: 'robots', content: 'index, follow'}
    ]);

    this.getIP();

    if(navigator.userAgent.match(/Android/i))
    {                                    
      this.device = "Android"
    }
    else if(navigator.userAgent.match(/iPhone|iPad|iPod/i))
    {                                  
      this.device = "IOS"
    }
    else
    {
      this.device = "Desk"
    }
  }  

  getIP()
  { 
    this.ip.getIPAddress().subscribe((res:any)=>{  
      this.ipAddress=res.ip;this.sendIp()});
  };

  sendIp()
  {
    if (this.ipAddress === this.ipAddressSend)
    {
      return;
    }
    this.ipAddressSend = this.ipAddress;

    this.log = 
      "[Type:1][When:" + new Date().toLocaleString() +
      "][Website:" + window.location.href + 
      "][IP adress:" + this.ipAddressSend +
      "][Device:" + this.device + "]";

    const GMailService = functions().httpsCallable('GMailService');
    GMailService
    (
      {
        message: this.log
      }
    ).then(function(result)
    {
      console.log(result);
    }
    ).catch(function(error)
    {
      console.log(error);
    });  
  }
}
