<div *ngIf="deviceDesktop">
    <manderin-top></manderin-top>
</div>  
<div *ngIf="!deviceDesktop">
    <manderin-top-mob></manderin-top-mob>
</div>

<div class="container">
    <h1>本頁內容暫僅提供英文版，敬請見諒。</h1>

    <h1>Privacy Policy Statement</h1>
    
    <div>This privacy policy statement was last updated on 22 December 2020.</div>
    
    <p></p>
    <h4>Overview</h4>
    <div>EKIOSA Services is committed to protecting the privacy of the personal information of its clients, members, partners and employees. We value the trust of the public and those we deal with, and recognise that maintaining this trust requires transparency and accountability in how we treat personal information that is shared with us.  This Privacy Policy Statement (“Policy”) has been adopted to guide how we collect, use and disclose the personal information acquired in the course of conducting our business and fulfilling our professional responsibilities.
    </div>
    
    <p></p>
    <div>We adhere to the requirements of the law and our professional standards, and as we conduct our business operations we frequently gather and use personal information. We seek consent to use this information when it is collected and recognise your expectation that it will be carefully protected. Our privacy practices require that we only use personal information outside of the original purpose for which it is collected after obtaining appropriate consent.         
    </div>
    
    <p></p>
    <div>
        Personal information is defined as any information that can be used to distinguish, identify or contact a specific individual, including information about age, income, opinions or beliefs, home location and family. However, business contact and other information about individuals that is available in the public domain, such as names, addresses and telephone numbers published in directories, are not considered personal information. Public information is not subject to privacy legislation and therefore is excluded from this policy and is not subject to the same protections as is the case with personal information. However, we do not disclose personal information about our staff, members, partners and clients without consent.
    </div>

    <p></p>
    <h2>Policies used when collecting, maintaining and using personal information</h2>

    <p></p>
    <h4>Collection and use of personal information</h4>
    <p></p>
    <div>We obtain personal information about you only if you choose to provide it, such as contact information (name and email address) that you may provide on our website.</div>
        
    <p></p>
    <h4>By submitting personal information to EKIOSA Services, you are also agreeing to the use of this information in accordance with this Policy.</h4>
    <p></p>
    <div>
        Your personal information is not used for other purposes, unless we obtain your permission, or unless otherwise required or permitted by applicable law or professional standards. 
    </div>

    <p></p>
    <h4>Sensitive Personal Information</h4>
    <div>
        EKIOSA Services generally collects only the personal information required to fulfill your request. If additional information is required, you will be notified of this at the point of collection. EKIOSA Services only collects "sensitive" personal information (i.e. personal information regarding a person's race, ethnicity, political beliefs, trade union membership, religious or similar beliefs, physical or mental health, sexual life or criminal record) if you voluntarily provide it to us, and only where it is required for a specific purpose, and is permitted to be collected by applicable law or professional standards. Please do not provide sensitive personal information to EKIOSA Services, unless you consent to our use of that information for its legitimate business purposes and consent to the transfer and storage of the information to and in EKIOSA Services databases.
    </div>

    <p></p>
    <h4>Consent</h4>
    <div>Your consent is required for the collection and proposed use of personal information when information is collected. Consent can be either explicit or implied and can be provided directly to us by you or your authorised representative. We require your explicit consent in writing or electronically for the use of your personal information.</div>

    <p></p>
    <h4>Cookies</h4>
    <div>EKIOSA Services websites uses cookies to enable web site functions and for tracking performance. EKIOSA Services policy governing our use of cookies can be found here: <a routerLink="/cookie" routerLinkActive="active">Cookie policy statement.</a></div>

    <p></p>
    <h4>Google Analytics</h4>
    <div>This website uses Google Analytics to track performance. For example, we may track visitor sessions and referral sources to our sites. We use this data to understand site performance to serve you better. We may also track the performance of promotional links to our site. No personal information is passed to Google Analytics. Note that Google Analytics stores its data within the United States of America and is therefore subject to US laws. Those wishing to opt out of Google Analytics data collection should use the Google Analytics Opt-out Browser Add-on.</div>

    <p></p>
    <h4>IP address</h4>
    <div>This website tracks the IP adress of website users. We use this data to understand were (potential) customers are geographically located (and compare this with Google Analytics results), and we will never share this information with others.</div>

    <p></p>
    <h4>Children</h4>
    <div>EKIOSA Services understands the importance of protecting children's privacy, especially in an online environment. Our sites are not intentionally designed for, or directed at, children under the age of 13, and our policy is never to knowingly to collect or maintain children’s information except where required as part of an engagement to provide professional services.</div>

    <p></p>
    <h4>Sharing and transfer of Personal Information</h4>
    <div>In some instances, EKIOSA Services may share your personal information with outside companies, service providers or vendors. This may include the transfer of this information across geographical borders to third parties working with us or on our behalf to help fulfill your requests. EKIOSA Services generally stores personal information in the the Netherlands but may also store personal information in other legal jurisdictions. By providing personal information online to us, you are consenting to the transfer and/or storage of your personal information in this manner. EKIOSA Services does not sell personal information to any third parties, nor will EKIOSA Services transfer your personal information to any third parties for their own direct marketing use.</div>

    <p></p>
    <h4>Compliance with laws and regulations</h4>
    <div>EKIOSA Services may be required to disclose personal information in response to requests of government or law enforcement agencies or where disclosure is required by applicable laws, court orders, or government regulations. These disclosures may also be needed for data privacy or security audits and/or to investigate or respond to a complaint or security threat.</div>

    <p></p>
    <h4>Choices</h4>
    <div>You are not required to submit any personal information to EKIOSA Services online, but certain personal information may be required in order for you to receive additional information about our services and events. EKIOSA Services may also ask for your permission for certain uses of your personal information, and you can agree to or decline those uses. If you opt-in for particular services or to receive communications from us, you will be able to unsubscribe at any time by following the instructions included in each communication. Where you unsubscribe from a service or communication, we will try to remove your information promptly, but may require additional information to process your request.</div>

    <p></p>
    <h4>Accuracy</h4>
    <div>Personal information will be maintained as completely, accurately and up-to-date as possible. You are encouraged to review, correct and update personal information. Under most circumstances you have the right to reasonable access to your personal information to correct any inaccuracies. You can also make a request to update or remove information about you by contacting EKIOSA Services and we will make all reasonable and practical efforts to comply with your request, so long as it is consistent with applicable law and professional standards.</div>

    <p></p>
    <h4>Information security</h4>
    <div>EKIOSA Services has reasonable security policies and procedures in place to protect personal information from unauthorised loss, misuse, alteration, or destruction. Appropriate physical and electronic measures are also used to help ensure that personal information is secure. To the best of our ability, access to your personal information is limited to those who require the information to fulfil their job responsibilities. Those individuals who have access to the data are required to maintain the confidentiality of such information. However, despite our best efforts, security cannot be absolutely guaranteed against all threats.</div>

    <p></p>
    <h4>Retention of personal information</h4>
    <div>Personal information will not be used or disclosed for purposes other than those for which it has been collected, except with your consent or as required by applicable law. We do not share personal information with unaffiliated third parties, except where necessary for legitimate professional and business needs, to carry out your requests, and/or as required and permitted by applicable law or professional standards. We also make reasonable efforts only to retain personal information so long as it is necessary to comply with your request and to delete personal information if you ask us to do so.</div>

    <p></p>
    <h4>Changes to this policy</h4>
    <div>EKIOSA Services may modify this Policy from time to time. When changes are made to this Policy, the date of the update will be provided on this page, so we encourage you to review our site periodically for changes.</div>

    <p></p>
    <h4>Policy questions and enforcement</h4>
    <div>EKIOSA Services is committed to protecting the online privacy of your personal information. If you have questions or comments about our administration of your personally identifiable information, please contact us via one of the contact forms at our website, including specifying your email address, and we will respond to you soon. You may also use this to communicate any concerns you may have regarding compliance with our Privacy Policy Statement.</div>
    <div class="container" style="background: none; position:relative; transform:translate(-1%,3%)">
        <message-form></message-form>
    </div>
    <p></p>
</div>

<div *ngIf="deviceDesktop">
    <manderin-bottom></manderin-bottom>
</div>
<div *ngIf="!deviceDesktop">
    <manderin-bottom-mob></manderin-bottom-mob>
</div>