<div class="card text-white border-warning border-1">
    <img src="assets/images/cif-nl.png" style="height: 6em" class="card-img">
    <div class="card-img-overlay">
      <h4 class="card-title rounded" style="background: rgb(255, 128, 0);background: rgba(255, 128, 0, 0.8);color: #ffffff; text-align: center;position:relative; transform:translate(0%, -15%)">Translations & Communication</h4>
    </div>
    <ul class="list-group list-group-flush text-dark">
      <li class="list-group-item" style="background-color:transparent;background-color: white;">Understanding, adapting, and translating into the Dutch language and culture</li>
      <li class="list-group-item">Translations into other European languages and cultures</li>
      <li class="list-group-item">Translations from Dutch into Chinese or Japanese</li>
      <li class="list-group-item">Marketing & sales material: website, leaflets, whitepapers, online advertising</li>
      <li class="list-group-item">Legal material: contracts, policies</li>
      <li class="list-group-item">Communications: support on (daily) conversations via e.g. emails or online meetings</li>
    </ul>
  </div>