<div class="card text-white border-warning border-1">
    <img src="assets/images/Hoofddorp.jpg" class="card-img">
    <div class="card-img-overlay">
      <h4 class="card-title rounded" style="background: rgb(255, 128, 0);background: rgba(255, 128, 0, 0.8);color: #ffffff; text-align: center;">办公空间</h4>
      <p class="card-text"></p>
    </div>
    <div class="card-header" style="background-color:peru;">
      你在找什么？ 位置，大小，弹性办公桌，费用，机场和火车连接，...
    </div>
    <ul class="list-group list-group-flush bg-white text-dark">
      <li class="list-group-item">与我们交谈以发现您的要求和愿望</li>
      <li class="list-group-item">我们在荷兰的办公市场中探索各种可能性，以确保最适合您的需求</li>
      <li class="list-group-item">我们一起讨论最佳解决方案和后续步骤</li>
    </ul>
</div>