<div *ngIf="deviceDesktop">
    <english-top></english-top>
</div>  
<div *ngIf="!deviceDesktop">
    <english-top-mob></english-top-mob>
</div>

<div class="container">
    
    <h1>Cookie Policy Statement</h1>

    <div>This cookie policy statement was last updated on 6 October 2020.</div>

    <p></p>
    <h2>Overview</h2>
    <div>Cookies are tiny data files that some websites place on your computer or other device when you visit. They're used by many website owners, but not by this EKIOSA Services website. Cookies are linked to the machine you use to visit our site and not to you as an individual.  Unless you login or provide personal data through your use of this website, no personal information is collected.</div>
    <p></p>
    <div>Cookies are commonly used by websites to remember some aspect of your current or previous visit.  This could include things like the choices you've made or preferences you've chosen during your visit. Websites commonly do this in order to improve or tailor your current and future visits to that site or other related sites.</div>
    <p></p>
    <div>One example may be when you visit a site that you have been to before, that site may use a cookie to check what information you've looked at previously to get an idea of the topics that may be of interest to you.</div>
    <p></p>
    <div>The information stored in a cookie can only be read by the website that placed the cookie or other sites that it has agreed to share information with.</div>
    <p></p>
    <div>EKIOSA Services website does not use cookies.</div>
    <p></p>
</div>

<div *ngIf="deviceDesktop">
    <english-bottom></english-bottom>
</div>
  
<div *ngIf="!deviceDesktop">
    <english-bottom-mob></english-bottom-mob>
</div>