<div class="card text-white border-warning border-1">
    <img src="assets/images/Hoofddorp.jpg" class="card-img">
    <div class="card-img-overlay">
      <h4 class="card-title rounded" style="background: rgb(255, 128, 0);background: rgba(255, 128, 0, 0.8);color: #ffffff; text-align: center;">Office space</h4>
      <p class="card-text"></p>
    </div>
    <div class="card-header" style="background-color:peru;">
      What are you looking for? Location, size, flex-desks, costs, airport & train connections, ...
    </div>
    <ul class="list-group list-group-flush bg-white text-dark">
      <li class="list-group-item">Talk to us to discover your requirements and wishes</li>
      <li class="list-group-item">We explore possibilities in the Dutch office market to ensure an optimal match for your needs</li>
      <li class="list-group-item">Together we discuss an optimal solution and follow-up steps</li>
    </ul>
</div>