<div class="card text-white border-warning border-1">
    <img src="assets/images/akson-1K8pIbIrhkQ-unsplash.jpg" class="card-img">
    <div class="card-img-overlay">
      <h4 class="card-title rounded" style="background: rgb(255, 128, 0);background: rgba(255, 128, 0, 0.8);color: #ffffff; text-align: center;">HR management</h4>
    </div>
    <div class="card-header" style="background-color:peru">
      Use our experience in managing HR matters, utilizing the laws & regulations in the Netherlands
    </div>
    <ul class="list-group list-group-flush bg-white text-dark">
      <li class="list-group-item">Recruitment</li>
      <li class="list-group-item">Utilize financial subsidy from the Dutch government</li>
      <li class="list-group-item">Work permits</li>
      <li class="list-group-item">Employment contracts</li>
      <li class="list-group-item">Tax returns for expats</li>
      <li class="list-group-item">Employee arrangements</li>
      <li class="list-group-item">Company lease cars</li>
      <li class="list-group-item">Works Council setup & advice</li>
    </ul>
  </div>