<div class="card text-white border-warning border-1">
    <img src="assets/images/cZJw2VzN.jpg" class="card-img">
    <div class="card-img-overlay">
    <h4 class="card-title rounded" style="background: rgb(255, 128, 0);background: rgba(255, 128, 0, 0.6);color: #ffffff; text-align: center;position:relative;transform:translate(0%,-55%)">Team support</h4>
    </div>
    <div class="card-header" style="background-color: peru">
      Team + individual support
    </div>
    <ul class="list-group list-group-flush bg-white text-dark">
      <li class="list-group-item">We organize team events, for fun and team building</li>
      <li class="list-group-item">Business related events such as off-site workshops</li>
      <li class="list-group-item">Local support for individuals: housing, medical doctor, banking, insurances, international schools for children, entertainment</li>
    </ul>
  </div>