<div class="alert alert-success " style="max-width: 900px;" role="alert" >
    <h4 class="alert-heading">服务请求</h4>
    <p>请使用此服务请求表来描述您的请求。</p>
    <hr>
    <p class="mb-0">在我们开始执行您的请求之前，我们会尽快给您答复，并为您提供清晰的费用概览。 对于基本要求，我们的竞争服务费用从50欧元（不含增值税）开始。</p>
</div>

<form style="max-width: 250pt;" (ngSubmit)="onSubmit()" #messageForm="ngForm">
    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        
        <div class="form-group">
            <select class="form-control" id="serviceType" formControlName="serviceType" required [(ngModel)]="serviceType" name="serviceType">
              <option *ngFor="let st of serviceTypesList" [value]="st">{{st}}</option>
            </select>
        </div>

        <div class="form-group">
            <textarea class="form-control" id="description" formControlName="description" name="description" rows="10" placeholder="详细说明" [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
            <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                <div *ngIf="f.description.errors.required">需要您的服务请求的详细说明</div>
            </div>
        </div>

        <div class="form-group">
            <input type="text" formControlName="firstName" placeholder="名字" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                <div *ngIf="f.firstName.errors.required">名字为必填项</div>
            </div>
        </div>
        <div class="form-group">
            <input type="text" formControlName="lastName" placeholder="姓" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
            <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                <div *ngIf="f.lastName.errors.required">必须填写姓氏</div>
            </div>
        </div>
        <div class="form-group">
            <input type="text" formControlName="email" placeholder="电子邮件" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">电子邮件为必填项</div>
                <div *ngIf="f.email.errors.email">电子邮件必须是有效的电子邮件地址</div>
            </div>
        </div>
        
        <div class="form-group">
            <button class="btn btn-primary">提交</button>
            <div *ngIf="submitted" style="color: white; position:absolute;transform:translate(40%,-130%)">谢谢，我们会尽快回复！</div>
        </div>
    </form>
</form>