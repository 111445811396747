<manderin-top></manderin-top>

<div class='jumbotronCurtainDesk'>
  <!-- Guide -->
  <div style="width:29rem;position:relative;transform:translate(4%,5%)">
    <manderin-card-guide></manderin-card-guide>
  </div>
  <!-- Message Form -->
  <div class="jumbotron" style="background:none; position:absolute; transform:translate(152%,-98%)">    
    <manderin-message-form></manderin-message-form>
  </div>
  <!-- VAT -->
  <div style="z-index:3; width:18rem; position:relative; transform:translate(6%,10%)">
    <manderin-card-vat></manderin-card-vat>
  </div>
  <!-- HR management -->
  <div style="z-index:3; width:18rem; position:relative; transform:translate(115%,-48%)">
    <manderin-card-hr-management></manderin-card-hr-management>
  </div>
  <!-- Partner management -->
  <div style="z-index:3; width:18rem; position:relative; transform:translate(225%,-190%)">
    <manderin-card-partner-management></manderin-card-partner-management>
  </div>
  <!-- Business travel -->
  <div style="z-index:3; width:18rem; position:relative;transform:translate(335%,-290%)">
    <manderin-card-business-travel></manderin-card-business-travel>
  </div>
  <!-- Translations & Communication -->
  <div style="z-index:3; width:18rem; position:relative;transform:translate(6%,-335%)">
    <manderin-card-translations></manderin-card-translations>
  </div>
</div>

<div class='jumbotronFlag'>
  <!-- Flag -->
  <div class='jumbotron' style="height:200px; background-color:darkred; position:relative;transform:translate(0%,-60%)"></div>
  <div class='jumbotron' style="height:200px; background-color:white"></div>
  <div class='jumbotron' style="height:200px; background-color:royalblue; position:relative;transform:translate(0%,-50%)"></div>

  <!-- One stop shop -->
  <div style="z-index:3; width: 18rem; position:relative;transform:translate(225%,-95%)">
    <manderin-card-one-stop-shop></manderin-card-one-stop-shop>
  </div>  
</div>

<div class='jumbotronWindmills'>
  <!-- Sales hunting -->
  <div style="z-index:3; width:18rem; position:relative;transform:translate(6%, -20%)">
    <manderin-card-sales-hunting></manderin-card-sales-hunting>
  </div>
  <!-- Financial risk management -->
  <div style="z-index:3; width:18rem; position:relative; transform:translate(115%, -165%)">
    <manderin-card-financial-risk></manderin-card-financial-risk>
  </div>
  <!-- New opportunities are waiting -->
  <div class="card text-white bg-info mb-3" style="max-width: 18rem; position:relative;transform:translate(335%,-381%)">   
    <div class="card-body">
      <h5 class="card-title">新机会在等待中...</h5>
      <p class="card-text">Covid之后改组</p>
      <p class="card-text">在经济衰退的情况下，实力较弱的参与者需要让步</p>
      <p class="card-text">政府为大量资金提供便利</p>
      <p class="card-text">新的商机正在等待新的参与者</p>
      <p class="card-text">现在轮到您了...开始吧</p>
    </div>
  </div>

  <button type="button" class="btn btn-lg btn-block" style="z-index: 3; position:relative;transform:translate(0%,-1800%);height: 30px;background-color:darkorange;"></button>
</div>

<div class='jumbotronUpdates'>
  <!-- Videos -->
  <div class="container" style="position:absolute;transform:translate(0%,5%)">
    <div class="row">
      <div class="col-sm-6">
        <iframe width="450" height="315" src="https://www.youtube.com/embed/uJoeHcu6IUg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div class="col-sm-6" style="position:relative;transform:translate(-15%,0%)">
        <iframe width="450" height="315" src="https://www.youtube.com/embed/r3DbGxC0EM8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
    <p></p>
    <div class="row">
      <div class="col-sm-6" style="position:relative;transform:translate(0%,-6%)">
        <iframe width="450" height="315" src="https://www.youtube.com/embed/LOn6OFYo-iA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div class="col-sm-6" style="position:relative;transform:translate(-15%,-6%)">
        <iframe width="450" height="315" src="https://www.youtube.com/embed/2fYlc6ikWE0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
    <p></p>
    <div class="row">
      <div class="col-sm-6" style="position:relative;transform:translate(0%,-6%)">
        <iframe width="450" height="315" src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FTaiwan.in.the.Netherlands%2Fvideos%2F1035350356899662%2F&show_text=false&width=560" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media" allowFullScreen="true"></iframe>
      </div>
      <div class="col-sm-6" style="position:absolute;transform:translate(85%,-16%)">
        <iframe width="450" height="315" src="https://www.youtube.com/embed/okve-cI0P10" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </div>
  </div>

  <!-- Our updates -->
  <div class="card text-white bg-info mb-3" style="z-index:3; max-width:18rem; position:relative;transform:translate(335%,-110%)">   
    <div class="card-body">
      <h5 class="card-title">及时定制更新</h5>
      <p class="card-text">一旦您开始使用我们的服务</p>
      <p class="card-text">我们会自动为您提供定制的更新和针对性的警报</p>
      <p class="card-text">这些适合您所要求的服务和您的业务类型</p>
      <p class="card-text">更新例如 修改了荷兰法律或法规</p>
      <p class="card-text">有关Covid相关事务的最新信息，例如政府支持</p>
      <p class="card-text">警报，例如 程序期限</p>
      <p class="card-text">适合您业务的其他各种更新</p>
      <p class="card-text">全部描述在一本定制的新闻通讯中，您可以完全配置</p>
    </div>
  </div>
  
  <div class="card text-white bg-info mb-3" style="max-width: 18rem; position:relative;transform:translate(335%,-130%)">   
    <div class="card-body">
      <h5 class="card-title">支持我们支持您</h5>
      <p class="card-text">我们会根据您的反馈添加服务</p>
      <p class="card-text">当前的服务报价基于现有的客户反馈</p>
      <p class="card-text">我们专注于来自台湾，中国大陆和日本的公司，因为我们拥有有关其特定服务要求的现有专业知识</p>
      <p class="card-text">我们欢迎任何在荷兰开展业务的组织的反馈意见</p>
    </div>
  </div>

  <div class="jumbotron" style="z-index:3; background:none; position:absolute; transform:translate(310%,-95%)">    
    <manderin-message-form></manderin-message-form>
  </div>

  <div class="alert alert-success bg-info text-white" style="position:relative;transform:translate(0%,168%)" role="alert" >
    <hr> 
    <h4 class="alert-heading">服务要求：请使用我们的 <a routerLink="/manderinServiceRequest" class="alert-link"> 服务申请表</a> 描述您的要求</h4>
    <hr>    
  </div>
</div>

<div class="jumbotronService">
  <!-- Company legal advice -->
  <div style="width: 18rem; position:relative;transform:translate(5%,5%)">
    <manderin-card-company-legal-advice></manderin-card-company-legal-advice>
  </div>

  <!-- Export/Import -->
  <div style="width: 18rem;;transform:translate(110%,-188%)">
    <manderin-card-export-import></manderin-card-export-import>
  </div>

  <!-- Office space -->
  <div style="width: 18rem; position:relative;transform:translate(110%,-135%)">
    <manderin-card-office-space></manderin-card-office-space>
  </div>

  <!-- Network management -->
  <div style="width: 18rem; position:relative;transform:translate(220%,-337%)">
    <manderin-card-network-management></manderin-card-network-management>
  </div>

  <!-- Team support -->
  <div style="width: 18rem; position:relative;transform:translate(220%,-277%)">
    <manderin-card-team-support></manderin-card-team-support>
  </div>

   <!-- Company insurances -->
   <div style="width: 18rem; position:relative;transform:translate(5%,-385%)">
    <manderin-card-company-insurances></manderin-card-company-insurances>
  </div>
</div>

<div style="height: 100px;">
  <manderin-bottom></manderin-bottom>
</div>