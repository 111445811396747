<div class="card text-white border-warning border-1">
    <img src="assets/images/akson-1K8pIbIrhkQ-unsplash.jpg" class="card-img">
    <div class="card-img-overlay">
      <h4 class="card-title rounded" style="background: rgb(255, 128, 0);background: rgba(255, 128, 0, 0.8);color: #ffffff; text-align: center;">人力资源管理</h4>
    </div>
    <div class="card-header" style="background-color:peru">
      利用我们在管理人事方面的经验，利用荷兰的法律法规
    </div>
    <ul class="list-group list-group-flush bg-white text-dark">
      <li class="list-group-item">招募</li>
      <li class="list-group-item">利用荷兰政府的财政补贴</li>
      <li class="list-group-item">工作准证</li>
      <li class="list-group-item">劳动合同</li>
      <li class="list-group-item">外籍人士的报税表</li>
      <li class="list-group-item">员工安排</li>
      <li class="list-group-item">公司租赁汽车</li>
      <li class="list-group-item">劳资委员会的设置与建议</li>
    </ul>
  </div>