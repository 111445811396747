<div class='bg-info' style="height:180px;padding-bottom:0.5%; margin-bottom:0%; overflow:visible;">
  <!-- logo -->
  <button type="button" class="btn ml-3 float-left"
      style="position:relative;transform:translate(-7%,0%)"
      routerLink="/manderin">
      <img src="assets/images/Ekiosa.logo.manderin.white.JPG" height="70">
  </button>

  <!-- policies -->
  <div class="p-2" style="position:absolute;transform:translate(2%,190%)">
    <a routerLink="/manderinDisclaimer" routerLinkActive="active" style="color: black;">Disclaimer & Copyright</a>
  </div>

  <div class="p-2" style="position:absolute;transform:translate(2%,260%)">
    <a routerLink="/manderinPrivacy" routerLinkActive="active" style="color: black;">Privacy policy</a>
  </div>

  <div class="p-2" style="position:absolute;transform:translate(100%,260%)">
    <a routerLink="/manderinCookie" routerLinkActive="active" style="color: black;">Cookie policy</a>
  </div>

  <div class="p-2" style="position:absolute;transform:translate(220%,260%)">
    <a routerLink="/manderinPoweredby" routerLinkActive="active" style="color: black;">Powered by</a>
  </div>

  <div class="container" style="position:absolute;transform:translate(-4%,340%)">
    <div class="p-2">©2020 EKIOSA Services. 版权所有.</div>
  </div>
</div>