<div class='container-fluid bg-info'>
    <div class="d-flex flex-row">
      <button type="button" class="btn ml-3 float-left" style="background-color:transparent; position:relative;transform:translate(1%,7%)" routerLink="/manderin" ><img src="assets/images/Ekiosa.logo.manderin.white.JPG" height="70" alt=""></button>
    
      <div class="container" style="position:absolute;transform:translate(27%,70%)">
        <div class="p-2">©2020 EKIOSA Services. 版权所有.</div>
      </div>
    </div>    

    <div class="d-flex flex-row-reverse" style="position:relative;transform:translate(-0%,-130%)">
      <div class="p-2"></div>
      <div class="p-2">
        <a routerLink="/manderinDisclaimer" routerLinkActive="active" style="color: black;">   Disclaimer & Copyright</a>
      </div>
      <div class="p-2"></div>
      <div class="p-2">
        <a routerLink="/manderinPrivacy" routerLinkActive="active" style="color: black;">   Privacy policy</a>
      </div>
      <div class="p-2"></div>
      <div class="p-2">
        <a routerLink="/manderinCookie" routerLinkActive="active" style="color: black;">   Cookie policy </a>
      </div>
      <div class="p-2"></div>
      <div class="p-2">
        <a routerLink="/manderinPoweredby" routerLinkActive="active" style="color: black;">   Powered by </a>
      </div>
    </div>
  </div>