<div class='bg-info' style="height:200px;padding-bottom:0.5%; margin-bottom:0%; overflow:visible;">
  <!-- logo -->
  <button type="button" class="btn ml-3 float-left"
      style="position:relative;transform:translate(-7%,0%)"
      routerLink="/english">
      <img src="assets/images/Ekiosa.logo.white.JPG" height="70">
  </button>

  <!-- policies -->
  <div class="p-2" style="position:absolute;transform:translate(2%,190%)">
    <a routerLink="/englishDisclaimer" routerLinkActive="active" style="color: black;">Disclaimer & Copyright</a>
  </div>

  <div class="p-2" style="position:absolute;transform:translate(3%,260%)">
    <a routerLink="/englishPrivacy" routerLinkActive="active" style="color: black;">Privacy policy</a>
  </div>

  <div class="p-2" style="position:absolute;transform:translate(100%,260%)">
    <a routerLink="/englishCookie" routerLinkActive="active" style="color: black;">Cookie policy</a>
  </div>

  <div class="p-2" style="position:absolute;transform:translate(220%,260%)">
    <a routerLink="/englishPoweredby" routerLinkActive="active" style="color: black;">Powered by</a>
  </div>

  <div class="container" style="position:absolute;transform:translate(-2%,330%)">
    <div class="p-2">Email: info@ekiosa.eu</div>
  </div>

  <div class="container" style="position:absolute;transform:translate(-3%,400%)">
    <div class="p-2">©2020 EKIOSA Services. All rights reserved.</div>
  </div>
</div>