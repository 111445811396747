// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAM27faJOwb7Hq4qW0NDNuhWqaQNEFfIkA",
	  authDomain: "ekiosa.firebaseapp.com",
	  databaseURL: "https://ekiosa.firebaseio.com",
	  projectId: "ekiosa",
	  storageBucket: "ekiosa.appspot.com",
	  messagingSenderId: "398556321865",
	  appId: "1:398556321865:web:7fc3aef97dda4dda2ea9da",
	  measurementId: "G-FEX5VQVM26"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
